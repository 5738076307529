import React, { ReactElement, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import FontSize from '@config/theme/definitions/fontSize'
import MaterialShopCart from '@components/materialShop/materialShopCart'
import { $materialShopPaths } from '@services/stores/materialShop'
import { useStore } from '@nanostores/react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    textAlign: 'left',
    paddingTop: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headerIcon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '84px',
      width: '84px',
    },
  },
  underlinedBtn: {
    borderRadius: 0,
    padding: theme.spacing(0, 0, 1),
    fontSize: FontSize['base'],
    '& svg': {
      height: FontSize['3lg'],
      width: 'auto',
    },
  },
}))

type CartProps = {
  data: {
    subline: {
      copy: {
        raw: string
      }
    }
  }
}

export default function RootIndex({ data }: CartProps): ReactElement {
  const subline = get(data, 'subline.copy')
  const classes = useStyles()
  const intl = useIntl()
  const materialShopPaths = useStore($materialShopPaths)
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.shop.label',
        }),
        link: materialShopPaths.shop,
      },
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.cart.label',
        }),
      },
    ]
  }, [])

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs crumbList={breadcrumbs} />
      </Container>

      <Container width={'sm'} className={classes.contentRoot}>
        <Headline level={1}>
          <FormattedMessage id={'materialshop.cart.headline'} />
        </Headline>
      </Container>
      <MaterialShopCart subline={subline} />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    subline: contentfulModuleText(
      id: { eq: "f474cb68-acd4-5658-ad39-0c2d45c0c0db" }
    ) {
      copy {
        raw
      }
    }
  }
`
