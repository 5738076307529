import React, { ReactElement, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@objects/button'

import {
  materialShopRemoveItem,
  IMaterialShopCartItem,
  materialShopConfig,
} from '@services/stores/materialShop'

import Image from '@objects/image'
import { Typography } from '@material-ui/core'
import FontSize from '@config/theme/definitions/fontSize'
import { useToggleComp } from '@hooks/index'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 0, 8, 0),
    display: 'inherit',
  },
  cartItem: {
    marginTop: theme.spacing(8),
    borderBottom: '1px solid #000',
  },
  cartItemBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cartItemImageAndHeadline: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  heading: {
    ...theme.typography.infotext,
    fontWeight: 700,
    textAlign: 'left',
    width: '70%',
    color: theme.palette.common.black,
    fontSize: FontSize['base'],
    lineHeight: '140%',
  },
  cartImage: {
    width: theme.spacing(15.5),
    height: theme.spacing(15.5),
    margin: theme.spacing(0, 7, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cartItemDelete: {
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    color: theme.palette.common.black,
    padding: theme.spacing(4, 8),
    '& > svg': {
      fontSize: theme.spacing(5.75),
    },
  },
  editButton: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(22.5),
    },
  },
  valuesHeadline: {
    fontSize: FontSize['xs'],
    fontWeight: 'bold',
  },
  linkSmall: {
    '& span': {
      fontSize: FontSize['xs'],
    },
  },
  includedItems: {
    margin: theme.spacing(6, 0),
    background: theme.palette.common.white,
  },
  includedItem: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(4),

    '& > label': { marginRight: 0 },
    '&:not(:last-child)::after': {
      position: 'absolute',
      content: '""',
      borderBottom: '1px solid rgba(0, 0, 0, 0.20)',
      width: '100%',
      bottom: '0',
      left: '0',
    },
  },
  includedItemImage: {
    margin: theme.spacing(0, 8),
    width: theme.spacing(15.5),
    border: '1px solid rgba(0, 0, 0, 0.20)',
    background: '#808080',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(22.5),
    },
  },
  contentSection: {
    width: '50%',
  },
  contentHeadline: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: 0,
    fontSize: FontSize['xs'],
  },
  contentValue: {
    margin: 0,
    fontWeight: '400',
    fontSize: FontSize['xs'],
    lineHeight: '140%',
  },
  contentValueBold: {
    fontWeight: '700',
  },
  contentValueBoldFirst: {
    marginTop: theme.spacing(4),
  },
}))

interface IMaterialShopCartItemProps {
  item: IMaterialShopCartItem
}

export default function MaterialShopCartItem({
  item,
}: IMaterialShopCartItemProps): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()

  const formConfig = useMemo(() => {
    const config = materialShopConfig.categories[item.category.identifier]
    if (!config.form) {
      return undefined
    }
    const { labelId, fields, sections } = config.form
    return {
      label: labelId ? (intl.messages[labelId] as string) : undefined,
      fields: fields
        ? fields.map((field) => {
            return {
              ...field,
              label: intl.messages[field.labelId] as string,
            }
          })
        : undefined,
      sections: sections
        ? sections.map((section) => {
            return {
              ...section,
              label: intl.messages[section.labelId] as string,
              fields: section.fields.map((field) => {
                return {
                  ...field,
                  label: intl.messages[field.labelId] as string,
                }
              }),
            }
          })
        : undefined,
    }
  }, [item])

  const content: {
    title: string
    sections?: { label?: string; values: string[] }[]
  } = useMemo(() => {
    const data: {
      title: string
      sections?: { label?: string; values: string[] }[]
    } = {
      title: item.title,
    }
    if (!formConfig) {
      return data
    }

    const categoryType = item.category.identifier

    switch (categoryType) {
      case 'promoStuff':
        return {
          title: `${item.values.fields?.['numberOfPieces']} x ${item.title}`,
        }
      case 'smallEvents':
        return {
          ...data,
          sections: [
            {
              label: formConfig.label,
              values: [
                item.values.fields?.['contactPerson'],
                item.values.fields?.['phone'],
                item.values.fields?.['email'],
              ].filter(Boolean) as string[],
            },
            ...(item.values.sections?.reduce((acc, section) => {
              return [
                ...acc,
                {
                  label: formConfig.sections?.[0].label,
                  values: [
                    section['date'],
                    section['reason'],
                    section['deliveryAddress'],
                  ].filter(Boolean) as string[],
                },
              ]
            }, [] as { label?: string; values: string[] }[]) || []),
          ],
        }
      case 'bigEvents':
        return {
          ...data,
          sections: item.values.sections?.reduce((acc, section, index) => {
            return [
              ...acc,
              {
                label: intl.formatMessage(
                  { id: 'materialshop.form.contactPersonHeadline' },
                  { count: index + 1 }
                ),
                values: [
                  section['contactPerson'],
                  section['phone'],
                  section['email'],
                ].filter(Boolean) as string[],
              },
              {
                label: formConfig.sections?.[1].label,
                values: [
                  section['date'],
                  section['reason'],
                  section['numberOfExpectedVisitors'],
                ].filter(Boolean) as string[],
              },
            ]
          }, [] as { label?: string; values: string[] }[]),
        }
      case 'bridgeBanners':
      case 'poster':
      case 'promotionBanners':
        return {
          ...data,
          sections: [
            {
              values: Object.entries(item.values.fields || {})
                .map(([key, value]) => {
                  const field = formConfig.fields?.find(
                    (entry) => entry.id === key
                  )
                  if (Number(value) <= 0 || !field) return ''
                  return `${value} x ${field?.label || key}`
                })
                .filter(Boolean),
            },
          ],
        }
      default:
        return {
          ...data,
        }
    }
  }, [formConfig])

  return (
    <Grid item xs={12} className={classes.cartItem}>
      <div className={classes.cartItemBanner}>
        <div className={classes.cartItemImageAndHeadline}>
          <Image image={item.images[0].fluid} className={classes.cartImage} />
          <Typography className={classes.heading}>{content.title}</Typography>
        </div>
        <Button
          icon="Trash"
          type="label"
          onClick={() => materialShopRemoveItem(item)}
          className={classes.cartItemDelete}
        >
          {intl.formatMessage({
            id: 'materialshop.form.remove',
          })}
        </Button>
      </div>
      {content.sections && (
        <div className={classes.content}>
          {content.sections.map((section, index) => (
            <div key={index} className={classes.contentSection}>
              {section.label && (
                <p className={classes.contentHeadline}>{section.label}</p>
              )}
              {section.values.map((value, index) => (
                <p
                  className={`${classes.contentValue} ${
                    !section.label ? classes.contentValueBold : ''
                  } ${
                    !section.label && index === 0
                      ? classes.contentValueBoldFirst
                      : ''
                  }`}
                  key={index}
                >
                  {value}
                </p>
              ))}
            </div>
          ))}
        </div>
      )}
      <Grid item xs={12} className={classes.editButton}>
        <Button
          type="underlined"
          icon="Create"
          className={`${classes.valuesHeadline} ${classes.linkSmall}`}
          onClick={() =>
            toggleOverlay(true, {
              type: 'materialShop',
              paperProps: { className: 'dark' },
              materialShopProps: {
                id: item.id,
                item,
              },
              closeButton: 'hide',
            })
          }
        >
          <FormattedMessage id="materialshop.form.editDetails" />
        </Button>
      </Grid>
    </Grid>
  )
}
